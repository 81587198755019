import React, { useContext } from "react"
import styled from "styled-components"
import { BrinkContext } from "../components/context/BrinkContext"
import { useTranslation } from "react-i18next"
import { containerMaxWidth } from "../constants"
import Layout from "../components/Layout"
import SecureLayout from "../components/SecureLayout"
import Breadcrumbs from "../components/ui/Breadcrumbs"
import Loader from "../components/ui/Loader"
import FilterProducts from "../components/filter/FilterProducts"
import { useLocation } from "@reach/router"

const Container = styled.div`
  max-width: ${containerMaxWidth};
  margin: 0 auto 6rem;
`

const Title = styled.h1`
  text-align: center;
  padding: 0 5rem;
  line-height: 3rem;
`

const FilterResultPage = ({ pageContext }) => {
  const { t } = useTranslation("translations")
  const { isLoading } = useContext(BrinkContext)

  const { loginEnabled } = pageContext.sanityLoginPage
  const LayoutComponent = loginEnabled ? SecureLayout : Layout

  const location = useLocation()

  const { gender } = location.state ?? {}

  return (
    <LayoutComponent
      meta={{ title: t("Filter results") }}
      invertedHeader
      pageContext={pageContext}
    >
      <Breadcrumbs>{t("Filter")}</Breadcrumbs>
      {isLoading && <Loader isLoading={isLoading} />}
      {!isLoading && (
        <Container>
          <Title>{t("Filter by")}</Title>
          <FilterProducts gender={gender} />
        </Container>
      )}
    </LayoutComponent>
  )
}

export default FilterResultPage
